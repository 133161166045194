<template>
  <div id="landing-page-container" class="landing-page-container brindo-aquaclub">
    <main class="landing-page-main">
      <header class="header">
        <img class="logo-brindo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo.png" alt="Brindo" title="Brindo" />
        <img class="logo-brindo-small" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo-small.png" alt="Brindo" title="Brindo" />
        <div class="links-wrap">
          <p>
            <a href="#" class="scroll-to-footer hide-mobile">Nous joindre</a>
            <span class="spacer hide-mobile">|</span>

            <a class="light link lang" href="/en/brindo-aquaclub">English</a>
          </p>
          <a href="#" class="cta-wrap js-open-modal">
            <p>Achetez vos billets</p>
          </a>
        </div>
      </header>

      <section class="hero">
        <div class="video-mobile-wrap">
          <a href="https://www.youtube.com/watch?v=kTIAxIlgieU" data-lity=""
            ><img class="play" src="https://tremblant-promo-demo.jnv.dev/assets/images/play-brindo.png" alt="Play" title="Play"
          /></a>
        </div>
        <div class="data-wrap">
          <h1 class="big-title">Un monde aquatique aux dimensions démesurées pour toute la famille</h1>
          <p class="regular-text">Réservez à l'avance et assurez votre place en cas de journées de grand achalandage.</p>

          <a href="#" class="cta-wrap js-open-modal">
            <p>Achetez vos billets</p>
          </a>
        </div>
        <div class="video-wrap">
          <a href="https://www.youtube.com/watch?v=kTIAxIlgieU" data-lity=""
            ><img class="play" src="https://tremblant-promo-demo.jnv.dev/assets/images/play-brindo.png" alt="Play" title="Play"
          /></a>
          <img class="video-background" src="https://tremblant-promo-demo.jnv.dev/assets/images/video-brindo.jpg" alt="Brindo" title="Brindo" />
        </div>
      </section>

      <section class="about">
        <div class="title-wrap">
          <h2 class="medium-title">Venez vivre la réalité du monde de Brind’O Aquaclub au cœur du Centre de villégiature Tremblant</h2>
        </div>
        <div class="about-content-wrap">
          <div class="img-wrap"></div>
          <div class="data-wrap">
            <p class="regular-text">Le centre familial de jeux aquatiques devient un incontournable, beau temps, mauvais temps.</p>
            <div class="list-wrap">
              <div class="bullet"><p>35 jeux d’eau interactifs</p></div>
              <div class="bullet"><p>4 glissades</p></div>
              <div class="bullet"><p>Bain à remous intérieur</p></div>
              <div class="bullet">
                <p>Piscine extérieure <span>(été seulement)</span></p>
              </div>
              <div class="bullet">
                <p>Bain à remous extérieur <span>(18+)</span></p>
              </div>
              <div class="bullet"><p>Vestiaire familial avec cabines fermées</p></div>
              <div class="bullet">
                <p>Douches <span>(savon et shampoing disponibles)</span></p>
              </div>
              <div class="bullet">
                <p>Casiers <span>(apportez votre cadenas ou achetez-en un à la boutique)</span></p>
              </div>
              <div class="bullet"><p>Préposés à l’accueil et à la surveillance</p></div>
              <div class="bullet">
                <p>Aire de lunch <span>(possibilité d'apporter votre lunch)</span></p>
              </div>
            </div>

            <a href="#" class="cta-wrap js-open-modal">
              <p>Achetez vos billets</p>
            </a>
          </div>
        </div>
      </section>

      <section class="slider-wrap">
        <h2 class="medium-title">Brind’O Aquaclub</h2>
        <div
          class="main-carousel"
          data-flickity='{ "cellAlign": "center", "wrapAround": true, "groupCells": true, "pageDots": false, "percentPosition": false, "contain": true, "lazyLoad": 2, "imagesLoaded": true }'
          tabindex="0"
        >
            <div class="carousel-cell is-selected" style="position: absolute; left: 0px">
            <p class="tag">4 glissades</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-1.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: 1010px">
            <p class="tag">Salle d'entraînement revampée</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-2.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: 2020px">
            <p class="tag">Bains à remous</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-3.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: 3030px">
            <p class="tag">35 jeux d'eau interactifs</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-5.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: 4040px">
            <!-- <p class="tag">4 glissades</p> -->
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-4.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: 5050px">
            <!-- <p class="tag">4 glissades</p> -->
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-6.jpg" alt="Brindo" title="Brindo" />
            </div>
            <div class="carousel-cell" style="position: absolute; left: -1010px">
            <!-- <p class="tag">4 glissades</p> -->
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/slider-7.jpg" alt="Brindo" title="Brindo" />
            </div>
        </div>
      </section>

      <section class="book-wrap">
        <h2 class="medium-title">Pourquoi réserver d'avance</h2>
        <p class="regular-text">L'achalandage étant plus important en périodes de pointe, procurez-vous votre accès à l'avance et assurer votre place.</p>

        <a href="#" class="cta-wrap js-open-modal">
          <p>Achetez vos billets</p>
        </a>
      </section>

      <section class="pricing-wrap">
        <div class="table-wrap">
          <h2 class="medium-title">Tarification</h2>
          <div class="table">
            <div class="head">
              <p>ADULTE (13+)</p>
            </div>
            <div class="row">
              <p>Jeux aquatiques</p>
              <p>20 $</p>
            </div>
            <div class="row">
              <p>Salle d'entraînement (16+)</p>
              <p>20 $</p>
            </div>
            <div class="row">
              <p>Combo Jeux aquatiques et salle d'entraînement (16+)</p>
              <p>25 $</p>
            </div>
            <div class="row">
              <p>Non-baigneur</p>
              <p>12 $</p>
            </div>
            <div class="row">
              <p>Accès aux douches seulement</p>
              <p>6 $</p>
            </div>
            <div class="head">
              <p>ENFANT (5-12)</p>
            </div>
            <div class="row">
              <p>Jeux aquatiques</p>
              <p>15 $</p>
            </div>
            <div class="head">
              <p>PEEWEE (3-4)</p>
            </div>
            <div class="row">
              <p>Jeux aquatiques</p>
              <p>10 $</p>
            </div>
            <div class="head">
              <p>BAMBIN (0-2)</p>
            </div>
            <div class="row">
              <p>Jeux aquatiques</p>
              <p>Gratuit</p>
            </div>
          </div>
        </div>
        <div class="data">
          <p class="regular-text">Les frais d’accès incluent :</p>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Bloc de 3 heures<br /><span>(bracelet de couleur)</span></p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>
              Prêt d’une serviette de bain par personne<br /><span
                >(location de serviettes additionnelles à 5 $ chacune, taxes et redevance incluses)</span
              >
            </p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Accès à l’aire de jeux aquatiques et aux bassins extérieurs<br /><span>(la piscine extérieure est accessible en été seulement)</span></p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Accès aux casiers<br /><span>(apportez votre cadenas ou achetez-en un à la boutique)</span></p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Accès au vestiaire familial avec cabines fermées</p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Accès aux douches</p>
          </div>

          <a href="#" class="cta-wrap js-open-modal">
            <p>Achetez vos billets</p>
          </a>
          <a href="/brindo-aquaclub/termes" class="modalites-link" target="_blank">Modalités</a>
        </div>
      </section>

      <section class="gym">
        <div class="data">
          <h2 class="medium-title">Nouvelle salle d'entrainement</h2>
          <p class="regular-text">
            Découvrez la nouvelle salle d’entraînement Brind’O Aquaclub avec son équipement à la fine pointe et des services d’encadrement personnalisés
            avec des experts certifiés.
          </p>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Entraînez-vous pendant que vos enfants s’amusent</p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Mur vitré donnant sur les jeux aquatiques</p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Nouvelle salle d'entraînement modernisée</p>
          </div>
          <div class="fake-bullet">
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/bullet.png" alt="Bullet" />
            <p>Services d’encadrement personnalisés avec des experts certifiés.</p>
          </div>
        </div>
        <div class="img-wrap"></div>
      </section>

      <section class="cross-sell">
        <h2 class="medium-title">Planifiez votre séjour</h2>
        <a
          href="https://www.tremblant.ca/planifiez/offres-forfaits?sc_lang=fr&amp;webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9#hebergement"
          target="_blank"
          class="cross-sell-bloc"
        >
          <img src="https://tremblant-promo-demo.jnv.dev/assets/images/cross-sell-hebergement.jpg" alt="Hébergement Tremblant" />
          <div class="data">
            <p>Découvrez nos meilleures offres sur l’hébergement</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/arrow-right.png" alt="arrow right" />
          </div>
        </a>
        <a
          href="https://www.tremblant.ca/planifiez/billets-passes?webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9"
          target="_blank"
          class="cross-sell-bloc"
        >
          <img src="https://tremblant-promo-demo.jnv.dev/assets/images/cross-sell-ski.jpg" alt="Hébergement ski" />
          <div class="data">
            <p>Découvrez nos meilleures offres sur les billets de ski</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/arrow-right.png" alt="arrow right" />
          </div>
        </a>
        <a
          href="https://www.tremblant.ca/quoi-faire/activites?webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9#filters=seasons:Winter"
          target="_blank"
          class="cross-sell-bloc"
        >
          <img src="https://tremblant-promo-demo.jnv.dev/assets/images/cross-sell-activites.jpg" alt="Hébergement activités" />
          <div class="data">
            <p>Découvrez l’ensemble des activités offertes à Tremblant</p>
            <img src="https://tremblant-promo-demo.jnv.dev/assets/images/arrow-right.png" alt="arrow right" />
          </div>
        </a>
      </section>

      <section class="reservation-groupe">
        <h2 class="medium-title">Planifiez votre séjour</h2>
        <p class="regular-text">L'achalandage étant plus important en périodes de pointe, procurez-vous votre accès à l'avance et assurer votre place.</p>

        <a href="tel:18887381777">1-888-738-1777</a>
      </section>

      <footer class="footer-brindo">
        <div class="map-wrap">
          <div class="coordonnees">
            <h3>Coordonnées</h3>
            <p>141 Chemin de Kandahar, Mont-Tremblant, QC J8E 1B1<br /><br /><a href="tel:18887381777">1-888-738-1777</a></p>
            <h3>Heures d’ouverture</h3>
            <h4>Brind'O Aquaclub</h4>
            <p>Lundi au vendredi : 11 h à 18 h<br />Samedi : 10 h à 19 h<br />Dimanche : 10 h à 18 h</p>
            <h4>Salle d'entraînement</h4>
            <p>Lundi au vendredi : 7 h 30 à 19 h<br />Samedi et dimanche : 9 h à 19 h</p>
          </div>
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2760.937465868877!2d-74.58753948410286!3d46.21169897911691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccf774cb1f0fc9f%3A0xb7c30b6ec6cf2eea!2sBrind'O%20Aquaclub!5e0!3m2!1sen!2sca!4v1580330222762!5m2!1sen!2sca"
              frameborder="0"
              style="border: 0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </footer>

      <div class="bottom-section">
        <img class="logo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo.png" alt="Logo Tremblant" title="Logo Tremblant" />
        <div class="links">
          <p>Tremblant © 2020</p>
          <p class="spacer">|</p>

          <a
            href="https://www.tremblant.ca/quoi-faire/activites/salle-entrainement-brindo?webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9"
            target="_blank"
            >FAQ</a
          >
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/politique-de-vie-privee/?sessionGUID=d43d131e-69ba-1265-71d4-4a4dda548762&amp;webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9"
            target="_blank"
            >Politique de vie privée</a
          >
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/conditions-dutilisation/?sessionGUID=d43d131e-69ba-1265-71d4-4a4dda548762&amp;webSyncID=97edd0d8-994e-31e4-3811-e86c5360ede7&amp;sessionGUID=343d1be7-5c95-9e23-378c-2a87617079b9"
            target="_blank"
            >Conditions d'utilisation</a
          >
        </div>
      </div>

      <div class="modal modal-overlay"></div>
      <div class="modal modal-form js-form-modal">
        <div class="form-wrapper" data-content="booking-full">
          <h3 class="medium-title">Choisissez une date</h3>

          <form
            class="form form-booking js-form"
            target="_blank"
            action="https://reservations.tremblant.ca/ecomm/shop/activities/2624850/en-US/?ProductCategoryID=1029"
          >
            <div class="input-wrapper">
              <label for="f-arrival" class="is-active">
                <span>Arrivée</span>
                <input
                  id="start_date"
                  class="single-datepicker start-date-datepicker picker__input"
                  type="text"
                  name="startdate"
                  value="11/20/2021"
                  required=""
                  readonly=""
                  aria-haspopup="true"
                  aria-expanded="false"
                  aria-readonly="false"
                  aria-owns="start_date_root"
                />
                <div id="start_date_root" class="picker" aria-hidden="true">
                  <div class="picker__holder" tabindex="-1">
                    <div class="picker__frame">
                      <div class="picker__wrap">
                        <div class="picker__box">
                          <div class="picker__header">
                            <div class="picker__month">Novembre</div>
                            <div class="picker__year">2021</div>
                            <div
                              class="picker__nav--prev picker__nav--disabled"
                              data-nav="-1"
                              role="button"
                              aria-controls="start_date_table"
                              title="Previous month"
                            ></div>
                            <div class="picker__nav--next" data-nav="1" role="button" aria-controls="start_date_table" title="Next month"></div>
                          </div>
                          <table id="start_date_table" class="picker__table" role="grid" aria-controls="start_date" aria-readonly="true">
                            <thead>
                              <tr>
                                <th class="picker__weekday" scope="col" title="Sunday">Dim</th>
                                <th class="picker__weekday" scope="col" title="Monday">Lun</th>
                                <th class="picker__weekday" scope="col" title="Tuesday">Mar</th>
                                <th class="picker__weekday" scope="col" title="Wednesday">Mer</th>
                                <th class="picker__weekday" scope="col" title="Thursday">Jeu</th>
                                <th class="picker__weekday" scope="col" title="Friday">Ven</th>
                                <th class="picker__weekday" scope="col" title="Saturday">Sam</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--outfocus picker__day--disabled"
                                    data-pick="1635652800000"
                                    role="gridcell"
                                    aria-label="10/31/2021"
                                    aria-disabled="true"
                                  >
                                    31
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1635739200000"
                                    role="gridcell"
                                    aria-label="11/01/2021"
                                    aria-disabled="true"
                                  >
                                    1
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1635825600000"
                                    role="gridcell"
                                    aria-label="11/02/2021"
                                    aria-disabled="true"
                                  >
                                    2
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1635912000000"
                                    role="gridcell"
                                    aria-label="11/03/2021"
                                    aria-disabled="true"
                                  >
                                    3
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1635998400000"
                                    role="gridcell"
                                    aria-label="11/04/2021"
                                    aria-disabled="true"
                                  >
                                    4
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636084800000"
                                    role="gridcell"
                                    aria-label="11/05/2021"
                                    aria-disabled="true"
                                  >
                                    5
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636171200000"
                                    role="gridcell"
                                    aria-label="11/06/2021"
                                    aria-disabled="true"
                                  >
                                    6
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636257600000"
                                    role="gridcell"
                                    aria-label="11/07/2021"
                                    aria-disabled="true"
                                  >
                                    7
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636347600000"
                                    role="gridcell"
                                    aria-label="11/08/2021"
                                    aria-disabled="true"
                                  >
                                    8
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636434000000"
                                    role="gridcell"
                                    aria-label="11/09/2021"
                                    aria-disabled="true"
                                  >
                                    9
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636520400000"
                                    role="gridcell"
                                    aria-label="11/10/2021"
                                    aria-disabled="true"
                                  >
                                    10
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636606800000"
                                    role="gridcell"
                                    aria-label="11/11/2021"
                                    aria-disabled="true"
                                  >
                                    11
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636693200000"
                                    role="gridcell"
                                    aria-label="11/12/2021"
                                    aria-disabled="true"
                                  >
                                    12
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636779600000"
                                    role="gridcell"
                                    aria-label="11/13/2021"
                                    aria-disabled="true"
                                  >
                                    13
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636866000000"
                                    role="gridcell"
                                    aria-label="11/14/2021"
                                    aria-disabled="true"
                                  >
                                    14
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1636952400000"
                                    role="gridcell"
                                    aria-label="11/15/2021"
                                    aria-disabled="true"
                                  >
                                    15
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1637038800000"
                                    role="gridcell"
                                    aria-label="11/16/2021"
                                    aria-disabled="true"
                                  >
                                    16
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--disabled"
                                    data-pick="1637125200000"
                                    role="gridcell"
                                    aria-label="11/17/2021"
                                    aria-disabled="true"
                                  >
                                    17
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--today"
                                    data-pick="1637211600000"
                                    role="gridcell"
                                    aria-label="11/18/2021"
                                  >
                                    18
                                  </div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637298000000" role="gridcell" aria-label="11/19/2021">19</div>
                                </td>
                                <td role="presentation">
                                  <div
                                    class="picker__day picker__day--infocus picker__day--selected picker__day--highlighted"
                                    data-pick="1637384400000"
                                    role="gridcell"
                                    aria-label="11/20/2021"
                                    aria-selected="true"
                                    aria-activedescendant="true"
                                  >
                                    20
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637470800000" role="gridcell" aria-label="11/21/2021">21</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637557200000" role="gridcell" aria-label="11/22/2021">22</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637643600000" role="gridcell" aria-label="11/23/2021">23</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637730000000" role="gridcell" aria-label="11/24/2021">24</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637816400000" role="gridcell" aria-label="11/25/2021">25</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637902800000" role="gridcell" aria-label="11/26/2021">26</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1637989200000" role="gridcell" aria-label="11/27/2021">27</div>
                                </td>
                              </tr>
                              <tr>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1638075600000" role="gridcell" aria-label="11/28/2021">28</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1638162000000" role="gridcell" aria-label="11/29/2021">29</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--infocus" data-pick="1638248400000" role="gridcell" aria-label="11/30/2021">30</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638334800000" role="gridcell" aria-label="12/01/2021">1</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638421200000" role="gridcell" aria-label="12/02/2021">2</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638507600000" role="gridcell" aria-label="12/03/2021">3</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638594000000" role="gridcell" aria-label="12/04/2021">4</div>
                                </td>
                              </tr>
                              <tr>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638680400000" role="gridcell" aria-label="12/05/2021">5</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638766800000" role="gridcell" aria-label="12/06/2021">6</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638853200000" role="gridcell" aria-label="12/07/2021">7</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1638939600000" role="gridcell" aria-label="12/08/2021">8</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1639026000000" role="gridcell" aria-label="12/09/2021">9</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1639112400000" role="gridcell" aria-label="12/10/2021">10</div>
                                </td>
                                <td role="presentation">
                                  <div class="picker__day picker__day--outfocus" data-pick="1639198800000" role="gridcell" aria-label="12/11/2021">11</div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="picker__footer">
                            <button class="picker__button--today" type="button" data-pick="1637211600000" disabled="" aria-controls="start_date">
                              aujourd'hui</button
                            ><button class="picker__button--clear" type="button" data-clear="1" disabled="" aria-controls="start_date">effacer</button
                            ><button class="picker__button--close" type="button" data-close="true" disabled="" aria-controls="start_date">ferme</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input type="hidden" name="startdate_submit" value="11/20/2021" />
              </label>
            </div>

            <button class="cta-wrap" type="submit">Suivant</button>
          </form>
        </div>

        <a href="#" class="popup-close-btn js-close-modal"
          ><img class="close-cross" src="https://tremblant-promo-demo.jnv.dev/assets/images/close-brindo.svg" alt="Close Tremblant"
        /></a>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Brindo',

  head() {
    return {
      title: {
        inner: 'Brind’O Aquaclub | Tremblant',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          name: 'description',
          content: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Brind’O Aquaclub | Tremblant' },
        { name: 'twitter:card', content: 'Brind’O Aquaclub | Tremblant' },
        {
          n: 'twitter:description',
          c: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Brind’O Aquaclub | Tremblant' },
        { property: 'og:site_name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          property: 'og:description',
          content: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
      ],
      link: [{ rel: 'stylesheet', href: '/legacy/site.combined.min.css' }],
      script: [{ type: 'text/javascript', src: '/legacy/site.combined.min.js', body: true }],
    }
  },

  mounted() {
    setTimeout(() => {
      window.site.App.init({ env: 'local', csrf: '', locale: 'fr', device: 'desktop', preview: false, general: { googleMaps: '' } })
    }, 755)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
