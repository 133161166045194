<template>
  <div id="landing-page-container" class="landing-page-container brindo-aquaclub brindo-terms">
    <main class="landing-page-main">
      <header class="header">
        <img class="logo-brindo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo.png" alt="Brindo" title="Brindo" />
        <img class="logo-brindo-small" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo-brindo-small.png" alt="Brindo" title="Brindo" />
        <div class="links-wrap">
          <p>
            <a href="#" class="scroll-to-footer hide-mobile">Nous joindre</a>
            <span class="spacer hide-mobile">|</span>

            <a class="light link lang" href="/en/brindo-aquaclub/terms">English</a>
          </p>
          <a href="#" class="cta-wrap js-open-modal">
            <p>Achetez vos billets</p>
          </a>
        </div>
      </header>

      <section class="brindo-terms-wrap">
        <h2 class="medium-title">Validité</h2>
        <ul>
          <li>Un billet donne droit à un accès à l’aire de jeux aquatiques ou à la salle d’entraînement pour un bloc de 3 heures.</li>
          <li>Les jeunes de 12 ans et moins doivent être accompagnés d’un adulte de 18 ans et plus dans l’aire de jeux aquatiques.</li>
          <li>
            Les utilisateurs de la salle d’entraînement doivent avoir 16 ans et plus. Carte d’identité avec photo obligatoire pour accéder à la salle
            d’entraînement.
          </li>
        </ul>
        <h2 class="medium-title">Achat</h2>
        <ul>
          <li>Peut être acheté sur place en tout temps au comptoir Brind’O Aquaclub.</li>
          <li>
            Peut être acheté 48 heures d’avance en ligne ou par téléphone au 1-888-738-1777, à l'exception du 15 au 20 février, du 29 février au 7 mars et
            du 14 au 21 mars 2020. Si vous souhaitez profiter des installations Brind’O Aquaclub lors de ces dates, veuillez vous présenter au comptoir
            Brind’O Aquaclub pour vous procurer vos accès.
          </li>
          <li>
            Pour obtenir un billet gratuit pour les bambins (2 ans et moins), veuillez vous présenter au comptoir Brind’O Aquaclub afin de récupérer un
            bracelet.
          </li>
          <li>Pour des groupes de 20 personnes et plus : réservation possible au 1-888-681-5978.</li>
        </ul>
        <h2 class="medium-title">Livraison</h2>
        <ul>
          <li>
            Avec hébergement : les billets vous seront livrés directement à votre hôtel. Veuillez présenter votre billet au comptoir de Brind’O Aquaclub.
          </li>
          <li>Sans hébergement : sur présentation de votre confirmation d’achat, récupérez votre bracelet au comptoir Brind'O Aquaclub.</li>
        </ul>
        <h2 class="medium-title">Remboursement</h2>
        <p>Si achetés d'avance :</p>
        <ul>
          <li>Jusqu’à 72 heures avant le début de votre activité, vous pouvez faire rembourser en totalité sur une carte de crédit.</li>
          <li>Après 72 heures avant votre activité : crédit sur une carte cadeau Tremblant avec frais de 25 $ (incluant la redevance et les taxes).</li>
        </ul>
        <h2 class="medium-title">Restrictions</h2>
        <ul>
          <li>Produit incessible (ne peut être transféré d'une personne à une autre).</li>
        </ul>
        <h2 class="medium-title">Règlements</h2>
        <ul>
          <li>Pour accéder aux glissades, les enfants de 12 ans et moins doivent être accompagnées d’un adulte de 18 ans et plus.</li>
          <li>Le port du maillot de bain est obligatoire pour accéder à l’aire de jeux aquatiques.</li>
          <li>La couche d’eau est obligatoire pour les enfants de 3 ans et moins.</li>
          <li>Il est obligatoire de prendre une douche avant d’accéder aux jeux d’eau.</li>
          <li>Le port des sandales ou des souliers d’eau est recommandé dans l’aire de jeux aquatiques.</li>
          <li>L’accès à la baignoire à remous extérieure est réservé aux adultes (18 ans et plus).</li>
          <li>L’accès à la salle d’entraînement est réservé aux 16 ans et plus.</li>
          <li>Aucune boisson alcoolisée tolérée sur les lieux.</li>
          <li>Aucun contenant de verre accepté sur les lieux.</li>
          <li>Nourriture et boissons non alcoolisées permises dans l’aire prévue à cet effet uniquement.</li>
          <li>
            Aucun équipement de flottaison n’est permis dans les glissades. Vestes de flottaison individuelles acceptée dans la piscine extérieure et la
            baignoire à remous intérieure.
          </li>
          <li>L’accès est interdit à toute personne qui présente les symptômes suivants :</li>
          <ul>
            <li>Fièvre</li>
            <li>Diarrhée ou nausée</li>
            <li>Infection contagieuse de la peau</li>
            <li>Plaies ouvertes</li>
          </ul>
          <li>Tout incident ou problème qui pourrait affecter la qualité de l’eau doit être rapporté au surveillant en poste.</li>
        </ul>
      </section>

      <div class="bottom-section">
        <img class="logo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo.png" alt="Logo Tremblant" title="Logo Tremblant" />
        <div class="links">
          <p>Tremblant © 2020</p>
          <p class="spacer">|</p>

          <a href="https://www.tremblant.ca/quoi-faire/activites/salle-entrainement-brindo" target="_blank">FAQ</a>
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/politique-de-vie-privee/?webSyncID=1ca4a145-a1b8-e476-8288-d47c79060022&amp;sessionGUID=d43d131e-69ba-1265-71d4-4a4dda548762"
            target="_blank"
            >Politique de vie privée</a
          >
          <p class="spacer">|</p>
          <a
            href="https://www.alterramtnco.com/conditions-dutilisation/?webSyncID=1ca4a145-a1b8-e476-8288-d47c79060022&amp;sessionGUID=d43d131e-69ba-1265-71d4-4a4dda548762"
            target="_blank"
            >Conditions d'utilisation</a
          >
        </div>
      </div>
    </main>

    <!--[if lt IE 9]>
      <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
      <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    <![endif]-->
  </div>
</template>

<script>
export default {
  name: 'Brindo',

  head() {
    return {
      title: {
        inner: 'Brind’O Aquaclub | Tremblant',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          name: 'description',
          content: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Brind’O Aquaclub | Tremblant' },
        { name: 'twitter:card', content: 'Brind’O Aquaclub | Tremblant' },
        {
          n: 'twitter:description',
          c: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Brind’O Aquaclub | Tremblant' },
        { property: 'og:site_name', content: 'Brind’O Aquaclub | Tremblant' },
        {
          property: 'og:description',
          content: 'Un monde aquatique aux dimensions démesurées pour toute la famille',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://tremblant-promo-demo.jnv.dev/files/meta/_1200x630_fit_center-center_none/slider-6.jpg',
        },
      ],
      link: [{ rel: 'stylesheet', href: '/legacy/site.combined.min.css' }],
      script: [{ type: 'text/javascript', src: '/legacy/site.combined.min.js', body: true }],
    }
  },

  mounted() {
    setTimeout(() => {
      window.site.App.init({ env: 'local', csrf: '', locale: 'fr', device: 'desktop', preview: false, general: { googleMaps: '' } })
    }, 755)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
